import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { Sun, Moon } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeSlug from 'rehype-slug';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import renderMathInElement from 'katex/dist/contrib/auto-render';
import 'katex/dist/katex.min.css';
import './maths_formatting.css'; 
import PasswordProtectedArticle from './PasswordProtectedArticle';

const ArticlePage = ({ darkMode, toggleDarkMode }) => {
  const { filename } = useParams();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const articleRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const fetchArticle = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/articles.json');
        const files = await response.json();
        const fileInfo = files.find(file => file.filename === `${filename}.md`);
        
        if (fileInfo) {
          setIsPasswordProtected(fileInfo.passwordProtected);
          if (!fileInfo.passwordProtected || isAuthenticated) {
            const articleResponse = await fetch(`/content/${filename}.md`);
            const text = await articleResponse.text();
            setArticle(text);
          }
        }
      } catch (error) {
        console.error('Error fetching article:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [filename, isAuthenticated]);

  useEffect(() => {
    if (location.state?.passwordProtected) {
      setIsPasswordProtected(true);
    }
  }, [location]);

  useEffect(() => {
    document.body.style.backgroundColor = darkMode ? '#000000' : '#F5F5DC';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [darkMode]);

  useEffect(() => {
    if (articleRef.current && !isLoading) {
      renderMathInElement(articleRef.current, {
        delimiters: [
          {left: "$", right: "$", display: false},
          {left: "$$", right: "$$", display: true}
        ],
        throwOnError: false
      });
    }
  }, [article, isLoading]);

  if (isLoading) {
    return (
      <div className={`min-h-screen font-mono p-8 flex items-center justify-center ${
        darkMode ? 'bg-black text-gray-300' : 'bg-[#f5f5dc] text-[#463730]'
      }`}>
        Loading...
      </div>
    );
  }

  if (isPasswordProtected && !isAuthenticated) {
    return <PasswordProtectedArticle onPasswordSubmit={setIsAuthenticated} darkMode={darkMode} />;
  }

  return (
    <div 
      data-theme={darkMode ? 'dark' : 'light'}
      className={`relative min-h-screen font-mono transition-colors duration-300 ${
        darkMode ? 'bg-black text-gray-300' : 'bg-[#f5f5dc] text-[#463730]'
      }`}
    >
      <div className="max-w-3xl mx-auto px-4 pt-8 flex-grow">
        <div className="flex items-center justify-between mb-6">
          <Link 
            to="/writings" 
            className={`text-lg font-bold transition-colors duration-300 ${
              darkMode 
                ? 'text-gray-300 hover:text-white'
                : 'text-[#463730] hover:text-[#008080]'
            }`}
          >
            <span className="no-underline">←</span>
            <span className="ml-2 underline">Back to Writings</span>
          </Link>

          <button 
            onClick={toggleDarkMode} 
            className={`p-2 rounded-full transition-colors duration-300 ${
              darkMode 
                ? 'bg-gray-300 text-black hover:bg-white'
                : 'bg-[#463730] text-[#f5f5dc] hover:bg-[#34495e]'
            }`}
            aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            {darkMode ? <Sun size={24} /> : <Moon size={24} />}
          </button>
        </div>

        <article ref={articleRef} className="prose max-w-none">
          <ReactMarkdown
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex, rehypeRaw, rehypeSlug]}
            components={{
              h1: ({node, children, ...props}) => <h1 className={`text-3xl font-bold mb-4 ${darkMode ? 'text-gray-100' : 'text-[#463730]'}`} {...props}>{children}</h1>,
              h2: ({node, children, ...props}) => <h2 className={`text-2xl font-bold mb-3 ${darkMode ? 'text-gray-200' : 'text-[#463730]'}`} {...props}>{children}</h2>,
              h3: ({node, children, ...props}) => <h3 className={`text-xl font-bold mb-2 ${darkMode ? 'text-gray-300' : 'text-[#463730]'}`} {...props}>{children}</h3>,
              h4: ({node, children, ...props}) => <h4 className={`text-lg font-bold mb-2 ${darkMode ? 'text-gray-300' : 'text-[#463730]'}`} {...props}>{children}</h4>,
              h5: ({node, children, ...props}) => <h5 className={`text-base font-bold mb-2 ${darkMode ? 'text-gray-300' : 'text-[#463730]'}`} {...props}>{children}</h5>,
              h6: ({node, children, ...props}) => <h6 className={`text-sm font-bold mb-2 ${darkMode ? 'text-gray-300' : 'text-[#463730]'}`} {...props}>{children}</h6>,
              p: ({node, children, ...props}) => <p className={`mb-4 ${darkMode ? 'text-gray-300' : 'text-[#463730]'}`} {...props}>{children}</p>,
              ul: ({node, children, ...props}) => <ul className={`list-disc list-outside ml-4 mb-4 ${darkMode ? 'text-gray-300' : 'text-black'}`} {...props}>{children}</ul>,
              ol: ({node, children, ...props}) => <ol className={`list-decimal list-outside ml-4 mb-4 ${darkMode ? 'text-gray-300' : 'text-black'}`} {...props}>{children}</ol>,
              li: ({node, children, ...props}) => (
                <li className={`mb-1 ${darkMode ? 'text-gray-300' : 'text-black'}`} 
                    style={{color: darkMode ? 'inherit' : 'black'}} 
                    {...props}>{children}</li>
              ),
              a: ({node, children, ...props}) => <a className={`${darkMode ? 'text-[#6fbf73] hover:text-[#8fd996]' : 'text-[#463730] hover:text-[#008080]'}`} {...props}>{children}</a>,
              blockquote: ({node, children, ...props}) => <blockquote className={`border-l-4 pl-4 italic my-4 ${darkMode ? 'text-[#6fbf73] border-[#6fbf73]' : 'text-[#463730] border-[#463730]'}`} {...props}>{children}</blockquote>,
              code: ({node, inline, className, children, ...props}) => {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={tomorrow}
                    language={match[1]}
                    PreTag="div"
                    className="rounded-md overflow-hidden"
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={`px-1 rounded ${darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-200 text-[#463730]'} ${className}`} {...props}>
                    {children}
                  </code>
                );
              },
              table: ({node, ...props}) => (
                <table className={`border-collapse table-auto w-full ${
                  darkMode ? 'text-gray-100 border-gray-700' : 'text-[#463730] border-black'
                }`} {...props} />
              ),
              thead: ({node, ...props}) => (
                <thead className={darkMode ? 'bg-black' : 'bg-[#f5f5dc]'} {...props} />
              ),
              th: ({node, ...props}) => (
                <th className={`border px-4 py-2 ${
                  darkMode ? 'border-gray-600 text-gray-300' : 'border-black text-[#463730]'
                }`} {...props} />
              ),
              td: ({node, ...props}) => (
                <td className={`border px-4 py-2 ${
                  darkMode ? 'border-gray-700 text-gray-300' : 'border-black text-[#463730]'
                }`} {...props} />
              ),
              caption: ({node, children, ...props}) => (
                <caption
                  className={`${darkMode ? 'text-gray-300' : 'text-[#463730]'}`}
                  style={{ backgroundColor: darkMode ? '#000000' : '#F5F5DC' }}
                  {...props}
                >
                  {children}
                </caption>
              ),
              span: ({node, className, children, ...props}) => {
                if (className?.includes('math')) {
                  return <span className={`${className}`} {...props}>{children}</span>
                }
                if (props.style && props.style.color) {
                  let color;
                  switch(props.style.color.toLowerCase()) {
                    case 'pink':
                      color = darkMode ? 'pink' : '#FF1493';
                      break;
                    case 'green':
                      color = darkMode ? 'green' : '#006400';
                      break;
                    case 'yellow':
                      color = darkMode ? 'yellow' : '#8B6914';
                      break;
                    default:
                      color = props.style.color;
                  }
                  return (
                    <span style={{...props.style, color: color}}>
                      {React.Children.map(children, child => {
                        if (typeof child === 'string') {
                          return child.replace(/\$(.*?)\$/g, match => {
                            const innerContent = match.slice(1, -1);
                            return `<span class="math math-inline" style="color: ${color}">$${innerContent}$</span>`;
                          });
                        }
                        return child;
                      })}
                    </span>
                  );
                }
                return <span className={className} {...props}>{children}</span>
              },
              div: ({node, className, children, ...props}) => {
                if (className?.includes('math')) {
                  return (
                    <div className={`my-4 ${className}`} {...props}>
                      {children}
                    </div>
                  )
                }
                return <div className={className} {...props}>{children}</div>
              },
            }}
          >
            {article}
          </ReactMarkdown>
        </article>
      </div>
    </div>
  );
};

export default ArticlePage;
