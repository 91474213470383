import React from 'react';
import { Link } from 'react-router-dom';
import { Sun, Moon, Github, ExternalLink } from 'lucide-react';

const ProjectItem = ({ number, title, description, technologies, github, article, darkMode }) => (
  <div className="mb-6"> {/* Reduced margin-bottom */}
    <h3 className={`text-xl font-bold mb-1 ${darkMode ? 'text-[#40e07d]' : 'text-[#463730]'}`}>
      {number}. {title}
    </h3>
    <p className="mb-2">{description}</p>
    <div className="flex flex-wrap gap-2 mb-2">
      {technologies.map((tech, index) => (
        <span key={index} className={`px-2 py-1 rounded-full text-sm ${
          darkMode ? 'bg-[#40e07d]/20 text-[#40e07d]' : 'bg-[#463730]/20 text-[#463730]'
        }`}>
          {tech}
        </span>
      ))}
    </div>
    <div className="flex space-x-3"> {/* Reduced space-x */}
      {github && (
        <a 
          href={github} 
          target="_blank" 
          rel="noopener noreferrer" 
          className={`flex items-center ${darkMode ? 'text-[#40e07d]' : 'text-[#463730]'} hover:underline`}
        >
          <Github size={20} className="mr-1" /> {/* Updated icon size */}
          GitHub
        </a>
      )}
      {article && (
        <a 
          href={article} 
          target="_blank" 
          rel="noopener noreferrer" 
          className={`flex items-center ${darkMode ? 'text-[#40e07d]' : 'text-[#463730]'} hover:underline`}
        >
          <ExternalLink size={20} className="mr-1" /> {/* Updated icon size */}
          Related Article
        </a>
      )}
    </div>
  </div>
);

const ProjectsPage = ({ darkMode, toggleDarkMode }) => {
  const projects = [
    {
      title: "MEB AutoGrad",
      description: "Auto-differentiation tool for MPNN, implemented with only a dependency on NumPy.",
      technologies: ["Software", "Auto-Differentiation", "Python"],
      github: "https://github.com/marcusebrady/MEB_autograd", 

    },
    {
      title: "ML-Paramterised Photolysis Rates for GEOS-Chem",
      description: "Fortran based XGBoost implementation in the GEOS-Chem Chemical Transport Model. Predicting photolysis rates during runtime.",
      technologies: ["XGBoost", "GEOS-Chem", "Fortran"],
      github: "https://github.com/marcusebrady/xgbphot",
    },
    {
      title: "JAX MPNN implementation for chemical systems",
      description: (
        <>
          Implemented a Message Passing NN (MPNN) in JAX, using the{" "}
          <a 
            href="https://arxiv.org/pdf/2102.03150" 
            target="_blank" 
            rel="noopener noreferrer"
            className={`${darkMode ? 'text-[#40e07d] underline' : 'text-[#463730] underline'}`}
          >
            PaiNN architecture
          </a>.
        </>
      ),
      technologies: ["JAX", "PaiNN", "Python"],
      github: "https://github.com/marcusebrady/JAX_PaiNN"
    },
    {
      title: "SPA Implementation",
      description: "Saddle Point Approximation tool, implemented with only a dependency on NumPy.",
      technologies: ["Mathematics", "Quantum Chemistry", "Python"],
      github: "https://github.com/marcusebrady/SPA_implementation", 
      article: "https://www.mebgrad.com/writings/SPA-Implement"

    },
  ];

  return (
    <div className={`relative min-h-screen font-mono transition-colors duration-300 ${
      darkMode ? 'bg-black text-white' : 'bg-[#f5f5dc] text-[#463730]'
    }`}>
      <div className="max-w-3xl mx-auto p-4 flex flex-col min-h-screen">
        <header className="mb-6"> {/* Reduced margin-bottom */}
          <div className="flex items-center justify-between mb-4"> {/* Adjusted alignment */}
            {/* Heading aligned to the left */}
            <h1 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-[#463730]'}`}>
              My Projects
            </h1>

            {/* Dark mode toggle aligned with the heading */}
            <button 
              onClick={toggleDarkMode} 
              className={`p-2 rounded-full transition-colors duration-300 ${
                darkMode 
                  ? 'bg-white text-black hover:bg-gray-500' 
                  : 'bg-[#463730] text-[#f5f5dc] hover:bg-[#34495e]'
              }`}
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {darkMode ? <Sun size={24} /> : <Moon size={24} />} {/* Updated icon size */}
            </button>
          </div>

          {/* Back to Home link, with the arrow not underlined */}
          <Link 
            to="/" 
            className={`text-lg font-bold transition-colors duration-300 ${
              darkMode 
                ? 'text-white hover:text-gray-300'
                : 'text-[#463730] hover:text-[#008080]'
            }`}
          >
            <span className="no-underline">←</span>
            <span className="ml-1 underline">Back to Home</span> {/* Reduced margin-left */}
          </Link>
        </header>

        {/* Project List */}
        <main className="flex-grow">
          <div className="space-y-6"> {/* Reduced space between projects */}
            {projects.map((project, index) => (
              <ProjectItem key={index} number={index + 1} {...project} darkMode={darkMode} />
            ))}
          </div>
        </main>
      </div>

      {/* Footer inside main layout, styled correctly */}
      <footer className={`absolute bottom-0 w-full border-t ${
        darkMode ? 'border-gray-500' : 'border-[#463730]/30'
      }`}>
        <div className="max-w-3xl mx-auto p-2"> {/* Reduced padding */}
          <p className={`text-sm text-center ${darkMode ? 'text-gray-500' : 'text-[#463730]/70'}`}>
            algorithms and caffeine | MEBGrad
          </p>
        </div>
      </footer>
    </div>
  );
};

export default ProjectsPage;
