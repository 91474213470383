import React, { useState } from 'react';

const PasswordProtectedArticle = ({ onPasswordSubmit, darkMode }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'NAD') {  // Replace with your actual password
      onPasswordSubmit(true);
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen ${
      darkMode ? 'bg-black text-white' : 'bg-[#f5f5dc] text-[#463730]'
    }`}>
      <form onSubmit={handleSubmit} className="w-full max-w-xs">
        <h2 className="text-2xl font-bold mb-4">Password Protected Article</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={`w-full px-3 py-2 mb-4 border rounded ${
            darkMode ? 'bg-gray-800 border-gray-600' : 'bg-white border-gray-300'
          }`}
          placeholder="Enter password"
        />
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <button
          type="submit"
          className={`w-full py-2 px-4 rounded ${
            darkMode 
              ? 'bg-[#40e07d] text-black hover:bg-[#2ecc71]' 
              : 'bg-[#463730] text-white hover:bg-[#34495e]'
          }`}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default PasswordProtectedArticle;
